.home-page-section{
  position: relative;
  overflow-x: hidden;
}

.home-container {
    display: flex;
    flex-direction: row;
    margin-left: 2%;
    width: 98%;
    // margin-bottom: 2%;
  }
  
  .home-ctn-left {
    flex: 50;
    margin: 2%;
    margin-top: 0;
    margin-right: 0%;
  }
  .home-ctn-left-head{
    display: flex;
    text-align: start;
    margin: 2% 1%;
    margin-bottom: 0%;
    width: 86%;
  }
  .home-ctn-left-head h2{
    font-size: 2rem;
    position: relative;
    color:#f3f3f3;
    line-height: 1.7;
  }
  .home-ctn-left-head span.Typewriter__cursor{
    visibility: hidden;
    position: absolute;
    top: 0;
  }
  .home-ctn-left-head h2 span.Typewriter__wrapper{

    font-size: 2rem;
    position: absolute;
    color: #000;
    margin: 0;
    line-height: 1.7;
    top: 0;
    
  }
  
  
  .home-ctn-left-body{
    display: flex;
    text-align: start;
    margin: 3% 1%;
  }
  .home-ctn-left-body p{
    color: #686868;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5;
    margin-top: 0;
  }
  .home-ctn-left-foot-btn a{
    text-decoration: none;
    color: #fff;
  }
  .home-ctn-left-foot{
    display: flex;
    text-align: start;
    margin: 3% 1%;
    align-items: center;
    width: 80%;
  }
  .home-ctn-left-foot-btn{
    width: 17%;
  }
  .home-ctn-left-foot-btn button{
    width: 95%;
    background: #61cf64;
    border: none;
    color: #fff;
    border-radius: 4px;
    font-size: 0.8rem;
    height: 6vh;
    max-height: 45px;
    font-weight: 600;
    line-height: 2;
    box-shadow: 0px 3px 15px rgb(46 229 157 / 40%);
  }
  .home-ctn-left-foot-btn button:hover{
    background-color: #2EE59D;
    color: #fff;
    transform: translateY(-2px);
  }
  .home-ctn-left-foot-icons{
        margin: 0 2%;
        width: 17%;
        display: flex;
        gap: 15px;
  }
  .home-ctn-left-foot-icons img{
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  .home-ctn-right {
    flex: 37;
    display: flex;
    align-items: center;
  }


  /* Home bottom section */
  /* Home bottom section */

  .home-bottom-section{
    display: flex;
    flex-direction: column;
    margin: 2% 0;
    width: 100%;
  }
  .home-bottom-section-one{
    width: 90%;
    display: flex;
    align-self: center;
    position: relative;
    flex-direction: column;
    // margin: 7% 0;
    margin-top: 12%;
  }
  .home-bottom-section-one-top{
    width: 75%;
    display: flex;
    flex-direction: column;
    align-self: center;
  }
  .home-bottom-section-one-top h2{
    font-size: 2rem;
    margin: 0;
  }
  .home-bottom-section-one-top p{
    color: #686868;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.6;
    margin-bottom: 0%;
  }
  .home-bottom-section-one img.quote-overlay{
    position: absolute;
    width: 20%;
    opacity: 0.08;
    z-index: -2;
    top: -5%;
    left: 40%;
  }
  .home-bottom-section-one-top p.author-p{
    text-align: end;
    font-weight: 700;
    // width: 20%;
    margin: 0;
    color: #000;
    position: relative;
    align-self: flex-end;
}

.home-bottom-section-one-top p.author-p img{
    width: 15px;
    margin-right: 5px;
}
  
  // contact us section
  // contact us section

  .home-bottom-section-one-bottom{
    margin: 1% 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .home-bottom-section-one-bottom img{
    width: 8%;
    border-radius: 50%;
  }
  .home-bottom-section-one-bottom-icons-ctn{
    width: 10%;
  }
  .home-bottom-section-one-bottom-icons-ctn img{
    width: 20%;
  }
  .home-bottom-section-two{
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    position: relative;
  }
  .home-bottom-section-two-head{
    width: 70%;
    text-align: start;
    margin: 0 2%;
    align-self: center;
  }
  .home-bottom-section-two-head h2{
    font-size: 3rem;
    font-weight: 900;
    margin-bottom: 0%;
  }
  .home-bottom-section-two-head p{
    font-weight: 500;
    font-size: 1.2rem;
    width: 75%;
    // margin: 0;
  }
  .home-bottom-section-two-head p span{
    color:blue;
  }
  .home-bottom-section-two-body{
    width: 70%;
    display: flex;
    text-align: start;
    margin: 0 2%;
    flex-direction: column;
    align-self: center;
  }
  .home-bottom-section-two-body-section{
    display: flex;
    justify-content: space-between;
    margin: 2% 0;
  }
  .home-bottom-section-two-body-section input{
    width: 45%;
    height: 48px;
    border-color: #80808021;
    border-radius: 6px;
    font-size: 1rem;
    padding: 0px 25px;
  }
  .home-bottom-section-two-body-section input::placeholder{
    font-size: 1rem;
    font-weight: 500;
    
    color:#9e9e9e;
    font-family: system-ui;
  }
  .home-bottom-section-two-body-section-message textarea{
    width: 96%;
    height: 24vh;
    border-color: #80808021;
    background-color: #ffffff;
    border-radius: 6px;
    padding: 20px 0;
    padding-left: 4%;
    font-size: 1rem;
    resize: none;
    font-family: system-ui;
  }
  .home-bottom-section-two-body-section-message textarea::placeholder{
    font-size: 1rem;
    font-weight: 600;
    color:#9e9e9e;
  }
  .home-bottom-section-two-body-section-message button{
    display: flex;
    margin: 2% 0;
    align-items: center;
    border: none;
    background-color: #007aff;
    border-radius: 25px;
    color: white;
    width: 15%;
    min-width: 125px;
    cursor: pointer;
    justify-content: space-around;
    font-size: 0.9rem;
    max-height: 50px;
    height: 8vh;
    font-weight: 600;
  }

  // section css
  // section css

  #homepage{
    height: 80vh;
    // padding-top: ;
    display: flex;
    align-items: center;
  }
  #products{
    // padding-top: 7%;
    position: relative;
  }
  #services{
    // padding-top: 5%;
    position: relative;
    // height: 100vh;
    display: flex;
    align-items: center;
  }
  

  /* home image */
  /* home image */
  /* home image */
  /* home image */

  .home-ctn-right-colash{
    position: relative;
    width: 70%;
  }
  .home-ctn-right-colash img.cloud-home{
    position:absolute; 
    top: -20%;
  }
  .home-ctn-right-colash img.man-home{
    width:100%;
    z-index: 1;
  }
  .home-ctn-right-colash img.cloud-left{
    position: absolute;
    top: -20%;
    left: 0%;
    z-index: 2;
    width: 30%;
  }
  .home-ctn-right-colash img.cloud-right{
    position: absolute;
    top: -40%;
    left: 70%;
    z-index: 2;
    width: 30%;
  }
  .home-ctn-right-colash img.cloud-bottom{
    position: absolute;
    top: 0%;
    left: 88%;
    z-index: 2;
    width: 30%;
  }
  .service-settings-icons{
    position:absolute;
    right: -15%;
    top: 8%;
  }
  .service-settings-icons-second{
    position: absolute;
    right: -13%;
    top: 56%;
    z-index: -1;
    width: 45%;
  }

.home-bottom-location-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}
.home-bottom-location-section{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 60%;
  border-radius: 4px;
  background-color: #ffffff;
  // padding: 2% 0;
  padding-top: 1%;
  box-shadow: 1px 1px 15px rgb(27 27 27 / 40%);
}
.home-bottom-location-ctn{
  display: flex;
  flex-direction: column;
  text-align: start;
}
.home-bottom-location-ctn-head{
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
  flex-direction: column;
}
.home-bottom-location-section-top{
  display: flex;
  justify-content: space-around;
}
.home-bottom-location-ctn-head img{
  width: 40px;
  height: 40px;
}
.home-bottom-location-section-footer img{
  width: 30px;
  height: 30px;
}
// .home-bottom-location-section-footer .home-bottom-location-ctn-head{
//   margin: 2px 0;
// }
.home-bottom-location-section-footer .home-bottom-location-ctn-head img{
 width: 20px;
 height: 20px;
}
.home-bottom-location-section-footer .home-bottom-location-ctn-head p{
 margin: 0;
}
.home-bottom-location-ctn-head p{
  font-size: 1rem !important;
}
.home-bottom-location-ctn h4{
  margin: 1% 0;
}
.home-bottom-location-ctn p{
 font-size: 0.8rem;
 margin: 0;
}
.home-bottom-location-section-footer{
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
}
  /* mediaQuery */
  /* mediaQuery */
  /* mediaQuery */

  // @media (max-width:1860px){
  //   .home-ctn-left-head h2 span.Typewriter__wrapper,.home-ctn-left-head h2{
  //     font-size: 2.8rem;
  //   }
    
  //   .home-ctn-left-body p{
  //     font-size: 1.5rem;
  //   }
  // }

  // @media (max-width:1380px){
  
  //   .home-ctn-left-head h2 span.Typewriter__wrapper,.home-ctn-left-head h2{
  //     font-size: 2.4rem;
  //   }
  //   .home-ctn-left-body p{
  //     font-size: 1rem;
  //   }
  //   .home-ctn-left-foot-btn button{
  //     max-height: 30px;
  //     font-size: x-small;
  //   }
  // }
  
  // @media (max-width:1024px){
  //   .home-container{
  //     flex-direction: column;
  //     align-items: center;
  //   }
  //   .home-ctn-left-head h2 span.Typewriter__wrapper,.home-ctn-left-head h2{
  //     font-size: 2.2rem;
  //   }
  //   .home-ctn-left{
  //     width: 90%;
  //     display: flex;
  //     flex-direction: column;
  //     margin: 0;
  //   }
  //   .home-ctn-left-foot{
  //     align-self: center;
  //     justify-content: center;
  //     z-index: 1;
  //   }
  //   .home-ctn-right{
  //     flex: 37 1;
  //     display: flex;
  //     justify-content: center;
  //   }
  //   .home-ctn-right-colash{
  //     width: 65%;
  //   }
  // }
  @media (max-width:890px){
    #homepage{
      height: auto;
    }
    .service-settings-icons{
      display: none;
    }
    .home-container{
      flex-direction: column;
      align-items: center;
    }
    .home-ctn-left-head h2 span.Typewriter__wrapper,.home-ctn-left-head h2{
      font-size: 2rem;
    }
    .home-ctn-left{
      width: 90%;
      display: flex;
      flex-direction: column;
      margin: 0;
    }
    .home-ctn-left-foot{
      align-self: center;
      justify-content: center;
      z-index: 1;
    }
    .home-ctn-right{
      flex: 37 1;
      display: flex;
      justify-content: center;
    }
    .home-ctn-right-colash{
      width: 65%;
    }
  }
  // @media (max-width:575px){
    
  //   .home-ctn-left-head h2 span.Typewriter__wrapper,.home-ctn-left-head h2{
  //     font-size: 1.5rem;
  //   }
  //   .home-ctn-left-body p{
  //     font-size: 0.8rem;
  //   }
  //   .home-ctn-left-foot-btn,.home-ctn-left-foot-icons{
  //     width: 35%;
  //   }
  // }

  @media (max-width:540px){
    body p{
      font-size: 1rem;
    }
    #homepage,.home-container{
      align-items: start;
    }
    .home-ctn-left,.home-ctn-left-head{
      width: 98%;
    }
    .home-ctn-left-head h2 span.Typewriter__wrapper,.home-ctn-left-head h2{
      font-size: 1.7rem;
    }
    .home-ctn-left-foot-btn button{
      width: 100%;
      font-size: 0.8rem;
    } 
    // .home-ctn-left-body p {
    //   font-size: 1rem;
      
    // }
    .home-bottom-section-one {
      margin-top: 0;
      margin-bottom: 4%;
    }
    .home-ctn-left-body p{
      font-size: 1rem;
    }
    .home-ctn-left-foot-btn{
      width: 25%;
    }
    .home-ctn-left-foot{
      width: 100%;
      justify-content: start;
    }
    .home-ctn-right{
      padding-top:40px;
      justify-content: start;
      padding-left: 35px;
    }
      .home-bottom-section-one-top p{
        font-size: 0.9rem;
      }
      .home-bottom-section-one-top p.author-p{
        // width: 30%;
      }
      .home-bottom-section-one-top p.author-p img{
        width: 10px;
        left: -12%;
      }
      .home-bottom-section-one-bottom img,.home-bottom-section-one-bottom-icons-ctn{
        width: 25%;
      }

      .home-bottom-section-two-head{
        width: 90%;
      }
      // .home-bottom-section-two-head h3{
      //   font-size: 1rem;
      // }
      .home-bottom-section-two-head h2{
        font-size: 2rem;
      }
      .home-bottom-section-two-head p{
        font-size: 1rem;
        width: 80%;
      }
      .home-bottom-section-two-body{
        width: 95%;
      }
      .home-bottom-section-two-body-section-message{
        display: flex;
        flex-direction: column;
      }
      .home-bottom-section-two-body-section-message textarea{
        width: 97%;
        height: 20vh;
      }
      .home-bottom-section-two-body-section-message button {
        font-size: 0.7rem;
        max-height: 40px;
        align-self: center;
      }
    .service-settings-icons-second{
      display: none;
    }
    .home-bottom-location-section{
      width: 75%;
      align-items: center;
    }
    .home-bottom-location-section-top{
      flex-direction: column;
      padding-left: 0px;
      gap: 10px;
    }
    .home-bottom-location-section-footer{
      padding-left: 15px;
    }
    .home-bottom-location-section-footer{
      flex-direction: column;
      padding-left: 0px;
      margin: 15px 0;
    }
    .home-bottom-location-section-footer .home-bottom-location-ctn-head{
      margin: 0;
    }
    .home-bottom-location-section-footer .home-bottom-location-ctn-head p{
      font-weight: 600;
      font-size: 0.8rem !important;
    }
  }

  // @media (min-height:1180px){
  //   .home-container{
  //     flex-direction: column;
  //   }
  // }
  @media (max-height:700px){
    #homepage{
      height: 110vh;
    }
    .home-ctn-left-head h2 span.Typewriter__wrapper,.home-ctn-left-head h2{
      font-size: 1.5rem;
    }
    .home-bottom-section{
      margin-top: 15%;
    }
  }
  /* AOS AOS AOS AOS */
  /* AOS AOS AOS AOS */
  /* AOS AOS AOS AOS */

  [data-aos="service-setting"] {
    transform: rotate(60deg);
    opacity: 1;
    transition-property: transform, opacity;
    &.aos-animate {
      transform: rotate(0);
      opacity: 1;
    }
  }
    [data-aos="service-setting-second"] {
    transform: rotate(90deg);
    opacity: 1;
    transition-duration: 4000ms;
    transition-property: transform, opacity;
    &.aos-animate {
      transform: rotate(0);
      opacity: 1;
    }
  }
  @media (max-width:916px){
    .home-bottom-location-section-footer {
      display: flex;
      padding: 10px 0;
      flex-direction: column;

      padding-left: 5%;
    }
    .home-bottom-location-section-footer .home-bottom-location-ctn-head{
      margin: 5px 0;
    }
  }
 @media (min-width:1851px) {
  .home-container{
    margin: 0 auto;
    width: 1850px;
  }
}
