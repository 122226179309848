.services-container{
    display: flex;
    flex-direction: column;
    margin: 5% 2%;
    margin-right: 0%;
    margin-top: 0%;
    position:relative;
    width: 98%;
    overflow-x: hidden;
}


.services-ctn-head{
    display: flex;
    flex-direction: column;
    width: 71%;
    text-align: start;
    margin: 2%;
    position: relative;
    margin-top: 1%;
}
.services-ctn-head-overlay{
    position: relative;
    /* height: 15vh; */
    /* min-height: 135px; */
}
.services-ctn-head h2{
    font-size: 3rem;
    position: absolute;
    margin: 0%;
    left: 2%;
    top: 35%;
}
.services-ctn-head h1{
    font-size: 7rem;
    opacity: 0.05;
    /* position: absolute; */
    top: 0%;
    left: -3%;
    margin: 0%;
}
.services-ctn-head p{
    color: #686868;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5;
    margin-left: 1%;
}

.Flipped{
    direction: rtl;
}
.services-ctn-body,.services-ctn-body-two,.services-ctn-body-three,.services-ctn-body-four,.services-ctn-body-five{
    /* display: flex;
    width: 100%;
    justify-content: center;
    height: 70vh;
    max-height: 420px;
    overflow-y: auto; */
    /* display: flex;
    flex-direction: column; */
    width: 90%;
    padding: 0 6%;
    height: 50vh;
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    scroll-snap-type: y mandatory;
}
.services-ctn-body::-webkit-scrollbar,.services-ctn-body-two::-webkit-scrollbar,.services-ctn-body-three::-webkit-scrollbar,.services-ctn-body-four::-webkit-scrollbar,.services-ctn-body-five::-webkit-scrollbar{
    width: 9px;
}
.services-ctn-body::-webkit-scrollbar-thumb{
    height: 2px;
    background-color: #ed2e54;
}

.services-ctn-body-two::-webkit-scrollbar-thumb{
    height: 2px;
    background-color: #5fbeb4;
} 

.services-ctn-body-three::-webkit-scrollbar-thumb{
    height: 2px;
    background-color: #827cdc;
} 

.services-ctn-body-four::-webkit-scrollbar-thumb{
    height: 2px;
    background-color: rgb(97 207 100);
}
.services-ctn-body-five::-webkit-scrollbar-thumb{
    height: 2px;
    background-color: rgb(248 167 254);
}
.services-ctn-body-parent{
    display: flex;
    flex-direction: column;
    margin: 2%;
    width: 85%;
    
}
.services-ctn-body-section-left{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    margin: 1%;
    scroll-snap-align: start;
    direction: ltr;
    height: 24.5vh;
    max-height: 245px;
}
.services-ctn-body-section-card-left{
    position: relative;
    display: flex;
}
.services-ctn-body-section-card-bg-left{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 9vw;
    height: 9vw;
    background-color: #ed2e54;
    border-radius: 3px;
    box-shadow: 0px 3px 5px rgb(237 46 84);
    z-index: 1;
}
.services-ctn-body-section-card-left img.numbericon{
    width: 60%;
    position: absolute;
    top: 45px;
    left: -38px;
    z-index: 0;
}
.services-ctn-body-section-card-bg-left img.clipboardicon{
    width:30%;
    z-index: 2;
}

.services-ctn-body-section-detail-left{
    text-align: start;
    width: 80%;
}

.services-ctn-body-section-detail-left p{
    color: #686868;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    width: 80%;
}


.services-ctn-body-section-right{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    align-self: flex-end;
    direction: ltr;
    scroll-snap-align: start;
    height: 24.5vh;
    max-height: 245px;
}
.services-ctn-body-section-card-right{
    position: relative;
    display: flex;
}
.services-ctn-body-section-card-bg-right{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 9vw;
    height: 9vw;
    background-color: #5fbeb4;
    border-radius: 3px;
    box-shadow: 0px 3px 5px 0px 3px 5px rgb(95 190 180);
    z-index: 1;
}
.services-ctn-body-section-card-left img.number-one{
    width: 50%;
    position: absolute;
    z-index: 0;
    left: -32%;
}
.services-ctn-body-section-card-right img.number-two{
    width: 50%;
    position: absolute;
    z-index: 0;
    left: 85%;
}
.services-ctn-body-section-card-left img.number-three{
    width: 50%;
    position: absolute;
    z-index: 0;
    left: -20%;
}
.services-ctn-body-section-card-right img.number-four{
    width: 50%;
    position: absolute;
    z-index: 0;
    left: 85%;
}
.services-ctn-body-section-card-left img.number-five{
    width: 50%;
    position: absolute;
    z-index: 0;
    left: -31%;
}

.services-ctn-body-section-card-bg-right img.clipboardicon{
    width:30%;
    z-index: 2;
}

.services-ctn-body-section-detail-right{
    /* text-align: end;
    width:80%; */


    text-align: start;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.services-ctn-body-section-detail-right p{
    color: #686868;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    width: 70%;
    float: right;
}


/* mediaQuery */
/* mediaQuery */
/* mediaQuery */
/* mediaQuery */


@media(max-width:540px){
    #services{
        padding-top: 0px;
        height: auto;
    }
    .services-container{
        margin-bottom: 0;
    }
    .services-ctn-head{
        width:95%;
        margin-top: 0;
        
    }
    .services-ctn-head p{
        font-size: 1rem;
    }
    .services-ctn-head h2{
        font-size: 1.75rem;
        position: absolute;
        margin: 0%;
        left: 4%;
        top: 30%;
    }
    .services-ctn-head h1{
        font-size: 4rem;
        opacity: 0.05;
        margin: 0%;
    }
    .services-ctn-body,.services-ctn-body-two,.services-ctn-body-three,.services-ctn-body-four,.services-ctn-body-five{
        /* height: 41.8vh;
        min-height: 418px; */
        background-color: #f2f2f27a;
    }
    .services-ctn-body-section-left{
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        height: 41vh;
        margin: 0;
        min-height:418px;
        direction: ltr;
        scroll-snap-align: start;
    }
    .services-ctn-body-section-right{
        justify-content: center;
        flex-direction: column-reverse;
        align-items: flex-start;
        height: 41vh;
        min-height: 418px;
        margin: 0;
        scroll-snap-align: start;
    }
    .services-ctn-body-section-card-bg-left,.services-ctn-body-section-card-bg-right{
        width: 20vw;
        height: 20vw;
    }
    .services-ctn-body-section-detail-left,.services-ctn-body-section-detail-right {
        width: 98%;
    }
    .services-ctn-body-section-detail-left p,.services-ctn-body-section-detail-right p{
        font-size: 0.9rem;
    }
    .services-ctn-body-section-detail-right h2{
        margin: 0%;
    }
    .service-settings-icons{
        right: -25%;
        top: 17%;
        width: 50%;
    }
    .services-ctn-body-two::-webkit-scrollbar-thumb,.services-ctn-body-three::-webkit-scrollbar-thumb{
        height: 2px;
        background-color: #5fbeb4;
    } 
    
    .services-ctn-body-five::-webkit-scrollbar-thumb{
        height: 2px;
        background-color: #827cdc;
    } 
    
    .services-ctn-body-four::-webkit-scrollbar-thumb{
        height: 2px;
        background-color: #5fbeb4;
    }
    /* .services-ctn-body-section-right{
        height: 290px;
    } */
}
/* @media (max-height:700px) {
    .services-ctn-head h2{
        font-size: 2rem;
        position: absolute;
        margin: 0%;
        left: 2%;
        top: 35%;
    }
} */
@media (min-width:1851px) {
    .services-container{
      margin: 0 auto;
      width: 1850px;
      margin-bottom: 5%;
    }
  }
