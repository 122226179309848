
#careers{
    overflow-x: hidden;
}
.careers-container{
    display: flex;
    flex-direction: column;
    margin: 2% 0;
    width: 100%;
}
.careers-ctn-section-one{
    display: flex;
    width: 95%;
    align-self: center;
    align-items: center;
    justify-content: space-between;
    margin: 2% 0;
}
.careers-ctn-section-one-left{
    text-align: start;
    width: 48%;
}
.careers-container p{
    color: #686868;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.6;
    margin: 1% 0;
}
.careers-container h2{
    font-size: 2rem;
}

.careers-ctn-section-one-right{
    width: 45%;
}
.careers-ctn-section-one-right img{
    width: 80%;
}

.careers-ctn-section-two{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.careers-ctn-section-two-head h2{
    background: linear-gradient(to right, #2d8a9e 0%, #8689ed 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 1% 0;
}
.careers-ctn-section-two-body{
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin: 3% 0;
}
.careers-ctn-section-two-body-ctn{
    width: 30%;
}


/* section 3 */
/* section 3 */
/* section 3 */

.careers-ctn-section-three{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.careers-ctn-section-three-body{
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin: 2% 0;
}
.careers-ctn-section-three-body-left{
    width: 45%;
}
.careers-ctn-section-three-left{
    width: 50%;
    display: flex;
    text-align: start;
    margin: 0 2%;
    flex-direction: column;
    align-self: center;
}
.careers-ctn-section-three-body-left-section{
    display: flex;
    justify-content: space-between;
    margin: 2% 0;
    margin-top: 0%;
}
.careers-ctn-section-three-body-left-section input{
    width: 45%;
    height: 48px;
    border: 1px dashed grey;
    border-radius: 6px;
    font-size: 1rem;
    padding: 0px 25px;
}
.careers-ctn-section-three-body-left-section input::placeholder{
    font-size: 1rem;
    font-weight: 500;
    color:#9e9e9e;
    font-family: system-ui;
}
.careers-ctn-section-three-body-left-section-message textarea{
    width: 95%;
    height: 24vh;
    border: 1px dashed grey;
    background-color: #ffffff;
    border-radius: 6px;
    padding-left: 5%;
    resize: none;
    font-size: 1rem;
    font-family: system-ui;
  }
  .careers-ctn-section-three-body-left-section-message textarea::placeholder{
    font-size: 1rem;
    font-weight: 600;
    /* padding: 0px 25px; */
    color:#9e9e9e;
}
.drag-over {
    border: 2px dashed blue!important;
    background-color: lightblue!important;
  }
.careers-ctn-section-three-body-right{
    width: 45%;
    background-color: #e6ecf7;
    border: 1px dashed grey;
    border-radius: 6px;

}

.careers-ctn-section-three-body-right-ctn{
    width: 100%;
    padding-top: 2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.careers-ctn-section-three-body-right-ctn h3{
    font-weight: 500;
}
.careers-ctn-section-three-body-right-ctn p{
    color: #8c8c8c;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6;
}
.three-body-right-ctn-icons{
    display: flex;
    flex-direction: column;
}
.three-body-right-ctn-btn{
    margin: 4%;
}
.three-body-right-ctn-btn button{
    height: 6vh;
    max-height: 40px;
    /* width: 20%; */
    border: none;
    border-radius: 3px;
    color: #fff;
    background: linear-gradient(to right, #2d8a9e 0%, #8689ed 100%);
    cursor: pointer;
}
.careers-ctn-section-three-foot button{
    border: none;
    margin: 2% 0;
    width: 10%;
    height: 7vh;
    max-height: 50px;
    background-color: #0023cf;
    font-size: 1rem;
    color: white;
    font-weight: 600;
    border-radius: 3px;
    cursor: pointer;
    box-shadow: 2px 2px 4px #0000ff4f;
}
.careers-ctn-section-three-foot button:hover{
    background-color: #3c8aab;
    color: #fff;
    transform: translateY(-2px);
}
.file-upload-ctn{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.file-upload-ctn img.resume-img{
    width: 20%;
}
.delete-file-upload{
    font-size: 1.5rem;
    color: #f60000;
    cursor: pointer;
}


@media(max-width:770px){
    .careers-ctn-section-one{
        width: 98%;
        flex-direction: column;
        height: 80vh;
        justify-content: start;
    }
    .careers-container p{
        font-size: 1.5rem;
    }
    .careers-container h2{
        font-size: 2.5rem;
    }
    .careers-ctn-section-one-left{
        width: 80%;
        margin: 2%;
    }
    .careers-ctn-section-one-right{
        width: 85%;
        margin: 2%;
    }
    .careers-ctn-section-one-right img{
        width: 100%;
    } 
    .careers-ctn-section-two-head{
        width: 98%;
        margin: 2%;
        text-align: center;
    }
    .careers-ctn-section-two-body{
        flex-direction: column;
        height: 45vh;
        width: 75%;
        align-self: center;
        justify-content: start;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .careers-ctn-section-two-body-ctn{
        width: 98%;
        margin: 2%;
    }
    .careers-ctn-section-three-body{
        flex-direction: column;
        width: 80%;
        align-self: center;
    }
    .careers-ctn-section-three-body-left,.careers-ctn-section-three-body-right{
        width: 98%;
        margin: 2%;
    }
    .careers-ctn-section-three-foot button{
        width: 30%;
        height: 4vh;
        font-size: 0.8rem;
    }
    .three-body-right-ctn-btn button{
        /* width: 25%; */
        height: 3vh;
        font-size: 0.8rem;
    }
}
@media(max-width:540px){
    #careers{
        display: flex;
        justify-content: center;
    }
    .careers-container{
        width: 90%;
    }
    .careers-ctn-section-one{
        width: 98%;
        flex-direction: column;
        height: 80vh;
        justify-content: start;
    }
    .careers-container h2 {
        font-size: 2rem;
    }
    .careers-container p{
        font-size: 1rem;
    }
    .careers-ctn-section-one-left{
        width: 98%;
        margin: 2%;
    }
    .careers-ctn-section-one-right{
        width: 98%;
        margin: 2%;
    }
    .careers-ctn-section-one-right img{
        width: 100%;
    } 
    .careers-ctn-section-two-head{
        width: 98%;
        margin: 2%;
        text-align: start;
    }
    .careers-ctn-section-two-body{
        flex-direction: column;
        height: 50vh;
        justify-content: start;
        width: 100%;
        align-self: start;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .careers-ctn-section-two-body-ctn{
        width: 98%;
        margin: 2%;
    }
    .careers-ctn-section-three-body{
        flex-direction: column;
        width: 100%;
        align-self: start;
    }
    .careers-ctn-section-three-body-left,.careers-ctn-section-three-body-right{
        width: 98%;
        margin: 2%;
    }
    .careers-ctn-section-three-foot button{
        width: 30%;
        height: 4vh;
        font-size: 0.8rem;
    }
    .three-body-right-ctn-btn button{
        /* width: 35%; */
        height: 3vh;
        font-size: 0.8rem;
    }
}