.products-container{
    display: flex;
    flex-direction: column;
    margin-left: 2%;
    width: 98%;
    position:relative;
    scroll-snap-type: y mandatory;
}
.products-container-product-background{
    position:absolute;
    width:100vw;
    top:20%;
    height:75%;
    left:0%;
    z-index:-5;
}
.products-container-header {  
    display: flex;
    flex-direction: column;
    text-align: start;
    margin: 1% 2%;
}
.products-container-header h2{
    font-size: 2rem;
    margin: 0;
}
.products-container-header h2.header-gradient{
    
	background: linear-gradient(to right, #ed0365 0%, #6e55de 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.products-container-body{
    display: flex;
    /* height: 80vh; */
    margin-bottom: 5%;
    scroll-snap-align: start;
}
.products-ctn-left{
    flex: 50;
    margin: 0 2%;
    display: flex;
    flex-direction: column;
}
.products-ctn-head-overlay{
    position: relative;
}
.products-ctn-head-overlay h2{
    font-size: 3rem;
    position: absolute;
    margin: 0%;
    left: 2%;
    top: 35%;
}
.products-ctn-head-overlay h1{
    font-size: 7rem;
    opacity: 0.05;
    /* position: absolute; */
    top: 0%;
    left: -3%;
    margin: 0%;
}
.products-ctn-left-head{
    display: flex;
    flex-direction: column;
    text-align: start;
}
.products-ctn-left-head-third-body{
    width: 75%;
}
.products-ctn-left-head h2{
    font-size: 1.5rem;
    width:77%;
}
.products-ctn-left-head p{
    color: #000000;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5;
    margin: 1% 0;
}
.products-ctn-left-body{
    width: 30%;
    display: flex;
    flex-direction: column;
    margin: 5% 0;
}
.products-ctn-left-body-right{
    width: 50% !important;
}
.products-ctn-left-body-icons{
    display: flex;
    flex-direction: column;
}
.products-ctn-left-body-section{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.products-ctn-left-body-section p{
    font-weight: 500;
    width: 75%;
    text-align: start;

}
.products-ctn-left-body-btn {
    text-align: start;
    margin: 5% 0;
}
.products-ctn-left-body-btn button{
    width: 70%;
    font-weight: 700;
    height: 36px;
    color: #000;
    border: none;
    border-radius: 20px;
    background-color: #f0f3f8;
}
.products-ctn-left-body-btn button:hover{

    background-color: #404040;
    color: #f0f3f8;
    transform: translateY(-2px);
}
.products-ctn-left-body-btn button:hover a{

    background-color: #404040;
    color: #f0f3f8;
    transform: translateY(-2px);
}
.products-ctn-left-body-btn button a{
    text-decoration: none;
    color: #000;
}


.products-ctn-right{
    flex: 50;

}
.products-ctn-right-section{
    width: 100%;
    position: relative;
    min-height: 480px;
}
.products-ctn-right-section img.screen1{
    width: 15vw;
    position: absolute;
    left: 0;
    top: -10%;
    box-shadow: 1px 1px 8px 1px rgb(162 162 162 / 40%);
    border-radius: 12px;
}
.products-ctn-right-section img.screen2{
    width: 15vw;
    left: 45%;
    box-shadow: 1px 1px 8px 1px rgb(162 162 162 / 40%);
    position: absolute;
    top: 30%;
    border-radius: 12px;
}
.products-ctn-left-section{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-right: 20px;
}
.products-ctn-left-section img.tabScreen{
    width: 100%;
    height: 95%;
}
.products-ctn-left-section-circle-red{
        content: "";
        border-radius: 50%;
        width: 13vw;
        height: 13vw;
        background-color: #ee116c;
        position: absolute;
        top: -11%;
        left: 71%;
        z-index: -1;
}
.products-ctn-left-section-circle-violet{
    content: "";
    border-radius: 50%;
    width: 16vw;
    height: 16vw;
    background-color: #4c4fed;
    position: absolute;
    top: 60%;
    left: -9%;
    z-index: -1;
}
.products-ctn-body-two-right{
    flex: 40 1;
    width: 100%;
    display: flex;
    justify-content: center;
}

.products-ctn-body-two-right-section{
    display: flex;
    flex-direction: column;
    /* width: 75%; */
    width: 100%;
    position: relative;
    /* height: 110vh; */
    justify-content: flex-start;
    margin-top: 20%;
}


.products-ctn-right-section img.oopacks-img{
    width: 100%;
    position: absolute;
    left: -15%;
    top: 15%;
    box-shadow: 1px 1px 8px 1px rgb(162 162 162 / 40%);
    border-radius: 25px;
}
.products-ctn-right-section img.oopacks-mobile-img{
    width: 25%;
    position: absolute;
    top: 70%;
    left: -56%;
    box-shadow: 1px 1px 8px 1px rgb(162 162 162 / 40%);
    border-radius: 25px;
}



/* mediaQuery */
/* mediaQuery */
/* mediaQuery */
/* mediaQuery */
@media (max-width:770px){
    .products-ctn-head-overlay h2{
        font-size: 1.75rem;
        position: absolute;
        margin: 0%;
        left: 2%;
        top: 25%;
    }
    .products-ctn-head-overlay h1{
        font-size: 4rem;
        opacity: 0.05;
        margin: 0%;
    }
    .products-container{
        margin-top: 20%;
    }
    .products-container-product-background{
        display: none;
    }
    .products-container-header h2{
        font-size: 2rem;
    }
    .products-container-body{
        flex-direction: column;
    }
    .products-container>:nth-child(4){
        flex-direction: column-reverse;
        height: 100vh;
    }
    .products-container>:nth-child(3){
        height: 100vh;
    }
    .products-container>:nth-child(5){
        margin-top: 15%;
        height: 100vh;
    }
    .products-ctn-right-section img.screen1{
        top: 0;
        left: 15%;
        width: 25vw;
    }
    .products-ctn-right-section img.screen2{
        top: 0;
        width: 25vw;
        left: 55%;
    }
    .products-ctn-left-head{
        width:95%;
        margin-top: 18%;
    }
    .products-ctn-left-head h2{
        width:95%;

    }
    .products-ctn-left-body{
        width: 100% !important;
    }
    .products-ctn-left-body-icons{
        flex-direction: row;
        justify-content: space-evenly;
    }
    .products-ctn-left-body-btn{
        align-self: center;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .products-ctn-left-body-btn button{
        width: 25%;
    }
    .products-ctn-left-section-circle-red{
        top: 0;
        left: 88%;
    }
    .products-ctn-left-section-circle-violet{
        top: 80%;
        left: -10%;
    }
    .products-ctn-body-two-right{
        flex: 20;
    }
    .products-ctn-body-two-right-section{
        width: 95%;
        margin-top: 0%;
    }
    .products-ctn-left{
        flex: 18;
    }
    .products-ctn-right-section img.oopacks-img{
        left: -2%;
        top: 0%;
    }
    .products-ctn-right-section img.oopacks-mobile-img{
        left: -2%;
        top: 0%;
        visibility: hidden;
    }
    .products-ctn-right-section>:nth-child(1),.products-ctn-body-two-right-section>:nth-child(1){
        display: none;
    } 
}
@media (max-width:540px){

    .products-container{
        margin-top: 0%;
    }
    .products-container p{
        font-size: 1rem;
    }
    .products-container-product-background{
        display: none;
    }
    .products-container-header h2.header-gradient{
        font-size: 1.5rem;
    }
    .products-ctn-head-overlay h2{
        font-size: 1.75rem;
        position: absolute;
        margin: 0%;
        left: 4%;
        top: 30%;
    }
    .products-ctn-head-overlay h1{
        font-size: 4rem;
        opacity: 0.05;
        margin: 0%;
    }
    .products-container-body{
        flex-direction: column;
    }
    .products-container>:nth-child(4){
        flex-direction: column-reverse;
        height: 75vh;
    }
    .products-container>:nth-child(3){
        height: auto;
    }
    .products-ctn-left .products-ctn-right-section{
        min-height: 180px;
    }
    .products-ctn-right .products-ctn-right-section{
        min-height: 250px;
    }
    .products-container>:nth-child(5){
        /* margin-bottom: 20%; */
        height: 100vh;
    }
    .products-ctn-right-section img.screen1{
        top: 0;
        left: 15%;
        width: 25vw;
    }
    .products-ctn-right-section img.screen2{
        top: 0;
        width: 25vw;
        left: 55%;
    }
    .products-ctn-left-head{
        width:95%;
    }
    .products-ctn-left-head h2{
        width:95%;

    }
    .products-ctn-left-body{
        width: 100% !important;
    }
    .products-ctn-left-body-icons{
        flex-direction: row;
        justify-content: space-evenly;
    }
    .products-ctn-left-body-btn{
        align-self: center;
        width: 45%;
    }
    .products-ctn-left-section-circle-red{
        top: 0;
        left: 88%;
    }
    .products-ctn-left-section-circle-violet{
        top: 80%;
        left: -10%;
    }
    .products-ctn-body-two-right{
        flex: 20;
    }
    .products-ctn-body-two-right-section{
        width: 95%;
    }
    .products-ctn-left{
        flex: 18;
    }
    .products-ctn-right-section img.oopacks-img{
        left: -2%;
        top: 0%;
    }
    .products-ctn-right-section img.oopacks-mobile-img{
        left: -2%;
        top: 0%;
        visibility: hidden;
    }
    .products-ctn-right-section>:nth-child(1),.products-ctn-body-two-right-section>:nth-child(1){
        display: none;
    } 
    .products-ctn-left-body-btn button {
        font-size: 0.5rem;
        width: 50%;
    }

}

@media (min-width:1851px) {
    .products-container{
      margin: 0 auto;
      width: 1850px;
    }
  }
@media (max-height:750px) {
    .products-container>:nth-child(4){
        
        height: 85vh;
    }
  }
























