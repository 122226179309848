.blog-contaier{
    display:flex;
    justify-content: center;
    align-items: center;
    height: 94vh;
}
.blog-contaier p{
    color:#50e3c2;
    font-weight: 600;
}
.blog-contaier img{
    
}