.up-click-ctn{
    cursor: pointer  ;
    position:absolute;
    left: 90%;
    top: 100%;
}
.up-click-ctn img{
    width: 40%;
    rotate: 180deg;
    mix-blend-mode: multiply;
}

@media (max-width:540px){
    .up-click-ctn{
        display: none;
    }
}