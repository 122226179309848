.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
/* body{
  scroll-behavior: smooth;
  background-image: url('./Assets/Elements/ProductsPage/homeBackground.png');
  background-repeat: no-repeat;
  background-position: center;

} */
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.loading-animation{
  
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: minimize 2s forwards;
}
.loading-animation img{
  width: 25%;
  animation: rotate 1s infinite linear;
}
.App {
  opacity: 0;
  animation: fadeIn 2s forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes minimize {
  50% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
/* #loadingLogo {
  animation: fadeIn 1s ease-in-out;
} */

/* @media (min-width:1851px) {
  body{
    margin: 0 auto;
    width: 1850px;
  }
} */