
.product-koopa-first-container{
    position: relative;
}
.koopa-background-image{
    width: 100%;
   /* height: 70vh; */
}
.koopa-rectangle-image-1{
  
    position: absolute;
    top: 44%;
    right: 15%;
    width: 17%;
}
.koopa-rectangle-image-3{
position: absolute;
top: 4%;
right: 4%;
width: 28%;
}
.koopa-rectangle-image-2{
    position: absolute;
    top: 24%;
    right: 18%;
    width: 22%;
}
.koopa-image-1{
    position: absolute;
    top: 12%;
    right: 18%;
    width: 30%;
}
.koopa-first-content{
    position: absolute;
    top: 18%;
    left: 3%;
    width: 35%;
    display: flex;
    text-align: start;
    flex-direction: column;
}
.koopa-first-content h1{
    margin: 1% 0;
}
.koopa-first-content p{
    line-height: 2;
    font-size: 1rem;
    font-weight: 600;
}
.koopa-side-heading{
    transform: rotate(-90deg);
    width: 10%;
    position: absolute;
   top: 70%;
}
.koopa-side-heading h1{
    font-size: 9rem;
    opacity: 0.05;
}
.product-koopa-second-container{
    padding:0 4%;
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 4% 0;
}
.product-koopa-second-left-border{
    height: 190px;
    position: absolute;
    border-left: 55px solid #b9dce5;
    left: 0%;
    top: 0;
}
.product-koopa-second-right-border{
    height: 190px;
    position: absolute;
    border-right: 55px solid #b9dce5;
    bottom: 0;
    right: 0;
}
.product-koopa-second-first-container{
    display: flex;
    position: relative;
}
.product-koopa-second-first-container-contenet{
    width: 69%;
    display: flex;
    flex-direction: column;
    text-align: start;
    margin: 0 2%;
}
.product-koopa-second-first-container-contenet h1{
    margin: 1% 0;
}
.product-koopa-second-first-container-contenet p{
    font-size: 1rem;
    font-weight: 500;
    line-height: 2;
}
.product-koopa-second-first-container img{
    position: absolute;
    right: 2%;
    bottom: 12%;
    width: 20%;
}
.product-koopa-second-second-container{
    display: flex;
    margin:4%;
    justify-content: space-between;
}
.product-koopa-second-second-container img{
    width: 20%;
}
.product-koopa-second-second-container-content{
    display: flex;
    flex-direction: column;
    text-align: start;
    width: 75%;
    justify-content: center;
}
.product-koopa-second-second-container-content h1{
    margin: 1% 0;
}
.product-koopa-second-second-container-content p{
    margin: 1% 0;
    font-size: 1rem;
    font-weight: 500;
    line-height: 2;
}
.product-koopa-second-third-container{
    display: flex;
    justify-content: space-between;
}
.product-koopa-second-third-container-content{
    display: flex;
    flex-direction: column;
    text-align: start;
    width: 75%;
    justify-content: center;
}
.product-koopa-second-third-container-content h1{
    margin: 1% 0;
}
.product-koopa-second-third-container-content p{
    margin: 1% 0;
    font-size: 1rem;
    font-weight: 500;
    line-height: 2;
}
.product-koopa-second-third-container img{
    width: 20%;
}

@media(max-width:770px){
    .koopa-first-content{
        width: 93%;
    }
    .koopa-first-content p{
        line-height: 1.4;
        font-size: 0.7rem;
        font-weight: 500;
        margin: 8% 0 0 0;
    }
    .koopa-first-content h1{
        font-size: 1.3rem;
    }
    .koopa-image-1{
        top: 2%;
    }
    .product-koopa-second-first-container{
        flex-direction: column;
        margin: 2% 4%;
    }
    .product-koopa-second-first-container-contenet{
        width: 98%;
      
    }
    .product-koopa-second-first-container-contenet h1{
        font-size: 1.2rem;
    }
    .product-koopa-second-first-container-contenet p{
        font-size: 0.8rem;
        line-height: 1.5;
        font-weight: 500;
    }
    .product-koopa-second-first-container img{
        position: relative;
    width: 50%;
    margin: 1% auto;
    }
    .product-koopa-second-second-container{
        flex-direction:column;
        position: relative;
        height: 75vh;
    }
    .product-koopa-second-second-container-content{
        width: 100%;
        position:absolute;
    }
    .product-koopa-second-second-container-content h1{
        font-size: 1rem;
    }
    .product-koopa-second-second-container-content p{
        font-size: 0.8rem;
        line-height: 1.5;
        font-weight: 500;
    }
    .product-koopa-second-second-container img{
        position: absolute;
        top: 40%;
        width: 50%;
       left: 23%;
    }
    .product-koopa-second-third-container{
        flex-direction: column;
        margin: 2% 4%;
    }
    .product-koopa-second-third-container-content{
        width: 100%;
    }
    .product-koopa-second-third-container-content h1{
        font-size: 1rem;
    }
    .product-koopa-second-third-container-content p{
        font-size: 0.8rem;
        line-height: 1.5;
        font-weight: 500;
    }
    .product-koopa-second-third-container img{
        width: 50%;
        margin: 1% auto;
    }
    .product-koopa-second-left-border{
        border: none;
    }
    .product-koopa-second-right-border{
        border: none;
    }
    .koopa-side-heading{
        width: 25%;
        top: 87%;
    }
    .koopa-side-heading h1{
        font-size: 7rem;
    }
}
