.product-oopacks-conatiner{
   position: relative;
   /* height: 100%; */
}
.oopacks-background-image{
    width: 100%;
    height: 100vh;
}
.oopacks-laptop-image{
    position: absolute;
    right: 4%;
    top: 4%;
    width: 40%;
}
.oopacks-main-content{
    position: absolute;
    left: 5%;
    top: 11%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
    width: 50%;
}
.oopacks-main-content p{
    line-height: 2;
    font-size: 1rem;
    font-weight: 500;
}
.oopacks-side-heading{
    /* font-size: 8rem;
    opacity: 0.05;
    transform: rotate(-90deg); */
    display: flex;
    position: relative;
    height: 40vh;

    
 

}
.oopacks-side-heading h1{
    font-size: 8rem;
    opacity: 0.05;
    transform: rotate(-90deg);
    margin: 0;
    position: absolute;
    left: -15rem;
    top: -9rem
}
.oopacks-side-heading img{
    position: absolute;
    left: 23%;
    bottom: -2rem;
    width: 16%;
   
}
.oopacks-product-second-heading{
    display: flex;
    text-align: start;
    width: 40%;
    position: absolute;
    right: 8%;
}
.oopacks-product-second-heading p{
    font-size: 1rem;
    font-weight: 500;
    line-height: 2;
}
.oopacks-product-last-container{
    padding: 4%;
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 4% 0;
}
.oopacks-product-last-container-left-border{
    height: 190px;
    position: absolute;
    border-left: 55px solid #a2d0a2;
    left: 0%;
    top: 0;
}
.oopacks-product-last-container-right-border{
    height: 190px;
    position: absolute;
    border-right: 55px solid #a2d0a2;
    bottom: 0;
    right: 0;
}
.oppacks-product-last-container-content{
    width: 45%;
    display: flex;
    text-align: start;
    margin: 2%;
}
.oppacks-product-last-container-content p{
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 2;
}
.oopacks-product-last-container-top-container{
    display: flex;
}
.oopacks-last-container-image{
    width: 15%;
    height: auto;
}
.oopacks-last-container-image1{
    width: 40%;
    height: auto;
}
.oopacks-product-last-container-second-container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.oopacks-product-last-container-second-cart-container{
    display: flex;
    width: 60%;
    margin: 4% 8%;

}
.oopacks-product-last-container-second-cart-container-left{
    width: 46%;
    background-color: #f3f3f3;
    display: flex;
    flex-direction: column;
    padding: 2% 4%;
    
}
.oopacks-product-last-container-second-cart-container-right{
    width: 50%;
}
.oopacks-product-last-container-second-cart-container-right img{
    width: 100%;
}
.oopacks-product-last-container-second-cart-container-left-heading{
    display: flex;
    position: relative;
    min-height:90px;
    
}
.oopacks-product-last-container-second-cart-container-left-heading h1{
   
    font-size: 2.1rem;
    opacity: 0.05;
    margin:0;
    position: absolute;
    left: 0%;
}
.oopacks-product-last-container-second-cart-container-left-heading h2{
    font-size: 1rem;
    margin: 0;
    position: absolute;
    top: 15%;
    left: 6%;
}
.oopacks-product-last-container-second-cart-container-left-heading1{
    font-size: 0.9rem;
    margin: 0;
    font-weight: 600;
    line-height: 1.4;
}
.oopacks-product-last-container-second-cart-container-left-heading2{
    font-size: 0.8rem;
    line-height: 1.7;
}
.oopacks-payment-methods{
    display: flex;
    flex-direction: column;
    text-align: start;
    margin: 6% 0;
}
.oopacks-payment-methods p{
   
}
/* .oopacks-payment-methods p:hover{
    font-weight: 700;
} */
.non-bold{
    margin: 3% 0;
    font-size: 0.8rem;
    font-weight: 500;
    cursor: pointer;
}
.bold-p{
    font-weight: 700;
}
.oopacks-cart-last-text{
    margin: 0 0 0 2%;
    font-size:1rem;
    font-weight: 600;
    opacity: 0.5;
}
.oopacks-cart-last-text-border{
    border: none;
    border-bottom: 1px solid rgb(182, 182, 182);
    width: 19%;
    margin: auto 2% auto auto;
}



/* mobile view */
@media(max-width:770px){
    .oopacks-laptop-image{
        width: 100%;
        top: -1%;
        left: -3%;
    }
    .oopacks-main-content{
        width: 90%;
        top: 8%;
    }
    .oopacks-main-content p{
       font-size: 0.8rem;
       margin: 0;
    }
    .oopacks-main-content h1{
        margin: 1% 0;
    }
    .oopacks-product-second-heading{
        top: 41%;
        width: 86%;
    }
    .oopacks-product-second-heading p{
        font-size: 0.8rem;
    line-height: 1.7;
    font-weight: 500;
    }
    .oopacks-side-heading img{
        top: -24%;
        left: 42%;
        width: 45%;
    }
    .oopacks-side-heading{
        height: 70vh;
    }
    .oopacks-product-last-container-top-container{
        display: flex;
        flex-direction: column;
    }
    .oppacks-product-last-container-content{
        width: 88%;
        margin: 0 0 0 14%;
    }
    .oppacks-product-last-container-content p{
        font-size: 0.8rem;
        font-weight: 500;
        line-height: 1.7;
    }
    .oopacks-last-container-image{
        width: 40%;
        margin: 0 auto;
    }
    .oopacks-last-container-image1{
        width: 80%;
        margin: 0 auto;
    }
    .oopacks-product-last-container-second-cart-container{
        flex-direction: column;
        width: 90%;
        margin: 0;
    }
    .oopacks-product-last-container-second-cart-container-left{
        width: 92%;

    }
    .oopacks-product-last-container-second-cart-container-left-heading{
        min-height: 45px;
    }
    .oopacks-product-last-container-second-cart-container-left-heading h1{
        font-size: 1.4rem;
    }
    .oopacks-product-last-container-second-cart-container-left-heading h2{
        top:8%
    }
    .oopacks-product-last-container-second-cart-container-right{
        width: 92%;
        padding: 2% 4%;
    }
    .oopacks-product-last-container-left-border{
        border: none;
    }
    .oopacks-product-last-container-right-border{
        border: none;
    }
}