
.footer{
    background: linear-gradient(to top, #d8d8fe, #fff);
    
    position: relative;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
}
.footer-container{
    display: flex;
    flex-direction: row;
    width: 96%;
    padding: 2%;
    padding-bottom: 0;
    justify-content: center;    
}
.footer-ctn-section-container{
    display: flex;
    width: 50%;
    justify-content: space-around;
}
.footer-ctn-section{
    content: "";
    width: 20%;
}
.footer-ctn-section ul{
    list-style: none;
    text-align: start;
}
.footer-ctn-section ul li{
    margin: 4% 0;
    font-size: 0.8rem;
    font-weight: 500;
    color: #000;
}
.footer-ctn-section ul li a{
    text-decoration: none;
    font-size: 0.8rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    color: #000;
}
.footer-ctn-section ul li a span{
    height: 25px;
    width: 30%;
    min-width: 90px;
    display: flex;
    height: 6vh;
    max-height: 27px;
    justify-content: center;
    align-items: center;
    color: white;
    background: linear-gradient(to right, #3700bd , #a400d0 );
    border-radius: 4px;
    margin: 0 15%;
}
.footer-ctn-section ul li a span p{
    font-size: 0.7rem;
}
.footer-ctn-section ul li.head{
    margin: 2% 0;
    font-size: 1.2rem;
    font-weight: 500;
    color: #9191a6;
}
.footer-ctn-section ul li p{
    font-size: 0.8rem;
    line-height: 1.5;
}
.footer-section-icons-ctn{
    width: 60%;
    display: flex;
    justify-content: space-between;
}
.footer-section-icons-img-bg{
    width: 30px;
    height:30px;
    background: transparent;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.footer-section-icons-img-bg a{
    font-size: 1rem;
    font-weight: 500;
}
.footer-section-icons-ctn img{
    width: 65%;
}
.footer-bottom{
    padding: 2% 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.footer-bottom p{
    font-size: 0.9rem;
    font-weight: 500;
    margin-bottom: 0;
}
.icons-mobile-view{
    display: none;
}


@media(max-width:770px){

    .footer-container{
        flex-direction: row;
        border-bottom: 1px solid #fff;
        justify-content: start;
    }
    .footer-ctn-section{
        width: 23%;
    }
    .footer-ctn-section ul li{
        
        font-size: 0.8rem;
    }
    .footer-ctn-section ul li a{
        margin: 2% -9%;
        font-size: 0.8rem;
    }
    .footer-ctn-section ul li.head{
        margin: 2% -9%;
        font-size: 1rem;
    }
    .footer-ctn-section ul li.head p{
        width: 95%;
        font-size: 1rem;
    }
    .footer-ctn-section ul li a span{
        max-height: 20px;
        min-width: 50px;
    }
    .footer-ctn-section ul li a span p{
        font-size: 0.5rem;
    }
    .footer-ctn-section ul li p{
        font-size:0.6rem;
    }
    .footer-bottom{
        text-align: center;
    }
    .footer-bottom p{
        font-size: 0.7rem;
    }
}
@media(max-width:540px){

    .footer-container{
        flex-wrap: wrap;
        border-bottom: 1px solid #fff;
        justify-content: space-around;
    }
    .footer-ctn-section{
        width: 42%;
    }
    .footer-ctn-section ul{
        padding-inline-start: 15px;
    }
    .footer-ctn-section ul li{
        margin: 2% -9%;
        font-size: 0.8rem;
    }
    
    .footer-ctn-section ul li a{
        margin: 0%;
    }
    .footer-ctn-section ul li.head{
        margin: 2% -9%;
    }
    .footer-ctn-section ul li.head p{
        width: 95%;
    }
    .footer-ctn-section ul li a span{
        max-height: 20px;
        width: 30%;
        min-width: 95px;
        font-size: 0.8rem;
    }
    .footer-ctn-section ul li a span p{
        font-size: 0.5rem;
    }
    .footer-bottom p{
        font-size: 0.5rem;
    }
    .icons-mobile-view{
        display: flex;
        width: 35% !important;
    }
    .standard-view-icons{
        display: none;
    }
}