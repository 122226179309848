.erp-product-container{
    display: flex;
    /* height: 100vh; */
    flex-direction: column;
   
}
.erp-product-container-one{
  height: 70vh;
  position: relative;
}
.erp-product-background-clr{
    width: 100vw;
    height: 70vh;
    z-index: 0;
}
.erp-product-image{
    position: absolute;
    top: 9%;
    width: 40%;
    left: 9%;
    height: auto;

}
.erp-product-content{
    position: absolute;
    top: 18%;
    right: 6%;
    width: 37%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.erp-product-content-one{
    font-size: 2rem;
    font-weight: 600;
    margin: 0;
}
.erp-product-content-two{
    font-size: 1.7rem;
    font-weight: 500;
    margin: 1% 0;
}
.erp-product-content-three{
    display: flex;
    text-align: start;
    margin: 3% 0;
}
.erp-product-content-three p{
    color: #686868;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.7;
    margin-top: 0
}
.erp-product-container-two{
    display: flex;
    flex-direction: column;
    margin: 1% 2%;
    /* height: 30vh; */
}
.erp-product-container-two-heading{
    display: flex;
    position: relative;
    min-height: 132px;
}
.erp-product-container-two-heading h1{
    font-size: 6rem;
    margin: 0;
    opacity: 0.05;
    position: absolute;
}
.erp-product-container-two-heading h2{
    font-size: 2rem;
    position: absolute;
    top: 35%;
    left: 4%;
    margin: 0;
}
.erp-product-container-two-content{
    display: flex;
    text-align: start;
    margin: 0% 0 1% 4%;
    width: 60%; 
}
.erp-product-container-two-content p{
    color: #000;
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 2;
    margin-top: 0
}
.erp-product-container-three{
    display: flex;
    flex-direction: column;
    /* margin: 1% 2%; */
    
}
.erp-product-types-of-erp-container{
    position: relative;
    padding:0 4% ;
    margin: 2% 0;
   
  
}
.erp-product-types-of-erp-container-left-border{
    height: 190px;
    position: absolute;
    border-left: 55px solid #ebe7fa;
    left: 0%;
    top: 0;
}
.erp-product-types-of-erp-container-right-border{
    height: 190px;
    position: absolute;
    border-right: 55px solid #ebe7fa;
    bottom: 0;
    right: 0;
}
.erp-product-types-of-erp-single-card-container{
    /* position: absolute;
    top: 0; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

}
.erp-product-types-of-erp-single-card{
    padding: 1%;
    width: 34%;
   min-height: 30vh;
   
    margin:2% 4%;
    border-radius: 16px;
    box-shadow: 1px 1px 15px rgba(27, 27, 27, 0.4);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.erp-product-types-of-erp-single-card img{
  width:80px;
  height: 80px;
}
.erp-product-types-of-erp-single-card h3{
    margin: 0;
}
.erp-product-types-of-erp-single-card-content{
    display: flex;
    text-align: start;
    margin: 0% 0 1% 0%;
}
.erp-product-types-of-erp-single-card-content p{
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #000;
    margin: 1% 0 0 0;
}

.erp-product-container-four{
    display: flex;
    flex-direction: column;
    margin: 3% 2%;
}
.erp-product-container-erp-features{
    display: flex;
    padding: 2%;
}
.erp-product-container-erp-features-left{
    width: 39%;
    display: flex;
    flex-direction: column;
    margin-left: 7%;
}
.erp-product-container-erp-features-right{
    width: 50%;
}
.erp-product-container-erp-features-left-single{
    display: flex;
    align-items: center;
}



@media(max-width:770px){
    .erp-product-image{
        position: absolute;
        left: 11%;
        width: 79%;
        height: 36%;
        top: 4%;
    }
    .erp-product-content{
        width: 85%;
        top: 36%;
    }
    .erp-product-content-three p{
        line-height: 1.5;
        font-size: 0.9rem;
    }
    .erp-product-container-two-content{
         width: 96%;
         margin: 0 1%;
    }
    .erp-product-container-two-content p{
        line-height: 1.5;
        font-size: 1rem;
    }
    .erp-product-container-two-heading{
        min-height: 75px;
    }
    .erp-product-container-two-heading h1{
        font-size:4rem;
    }
    .erp-product-container-two-heading h2{
        font-size: 1.5rem;
        left: 7%;
        top: 37%;
    }
    .erp-product-container-three-heading{
        min-height: 75px;
    }
    .erp-product-container-three-heading h1{
        font-size:3.4rem;
    }
    .erp-product-container-three-heading h2{
        font-size: 1.4rem;
        top: 33%;
         left: 5%;
    }
    .erp-product-types-of-erp-container-left-border{
        border: none;
    }
    .erp-product-types-of-erp-container-right-border{
        border: none;
    }
    .erp-product-types-of-erp-single-card{
        width: 95%;
        padding: 2% 3%;
    }
    .erp-product-types-of-erp-single-card-content p{
        font-size: 0.9rem;
    }
    .erp-featture-heading{
        min-height: 82px;
    }
    .erp-featture-heading h1{
        font-size:3rem;
    }
    .erp-featture-heading h2{
        font-size:1.5rem;
        top: 23%;
        left: 6%;
    }
    .erp-product-container-erp-features-left{
        margin: 0;
        width: 50%;
    }
    .erp-product-container-erp-features-left-single h3{
        font-size: 0.8rem;
    }
    .erp-product-container-erp-features-left-single img{
        width: 50px;
        height: 50px;
    }
    
}
   

