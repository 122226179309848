#about{
    overflow-x: hidden;
}
.about-container{
    display: flex;
    flex-direction: column;
    margin: 2% 0;
    width: 100%;
    position: relative;
}
.about-top-section{
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-bottom: 5%;
}
.about-top-section-body-ctn{
    width: 98%;
    height: 90vh;
    align-self: center;
    justify-content: space-between;
    display:flex;
    align-items: center;
    position:relative;
}
.about-top-section>:nth-child(2){
    height: 80vh;
}
.about-top-section>:nth-child(3){
    position: relative;
    height: 80vh;
    align-items: start;
}
.about-top-section-body-ctn-left-one{
    width: 50%;
    margin-left: 4%;
}

.about-top-section-body-ctn-left-one img{
    width: 30%;
}
.about-top-section-body-ctn-right-one{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 4%;
    text-align: start;
    position: relative;
}
.about-container h2{
    background: linear-gradient(to right, #ed0365 0%, #6e55de 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 3rem;
    margin: 1% 0;
}
.about-container h3.about-container-vision-head{
    font-size: 1.7rem;
    margin: 0;
}
.about-container p{
    color: #686868;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5;
    width: 95%;
    margin-bottom: 0;
}
.about-top-section-body-ctn-left-two{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 4%;
    text-align: start;
   
}
.about-top-section-body-ctn-left-one-sec{
    width: 50%;
    margin-left: 4%;
    position: relative;
}

.about-top-section-body-ctn-right-two{
    width: 50%;
    margin-left: 4%;
}
.about-top-section-body-ctn-right-two img{
    width: 80%;
}
.about-top-section-body-ctn img.about-background-img{
    position:absolute;
    z-index:-5;
    left:-1%;
    bottom:-15%;
    width:100vw;
}
/* ABOUT section 2 */

.about-bottom-section{
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-bottom: 5%;
}
.bottom-section-head-ctn{
    width: 98%;
    align-self: center;
    justify-content: space-between;
    display:flex;
    position: relative;
}
.bottom-section-head-ctn-left{
    width: 50%;
    text-align: start;
    margin-left: 4%;
    
    position: relative;
}

.bottom-section-head-ctn-left-top{
    width: 100%;
}
.bottom-section-head-ctn-left-bottom{
    position: relative;
    height: 25vw;
    margin: 10% 0;
}
.bottom-section-head-ctn-left-bottom-pink{
    content: "";
    position: absolute;
    left: -12%;
    z-index: 1;
    width: 25vw;
    height: 22vw;
    background-color: #ed0365;
}
.bottom-section-head-ctn-left-bottom-white{
    content: "";
    position: absolute;
    left: -6%;
    top: 8%;
    width: 25vw;
    height: 18vw;
    z-index: 2;
    background: #fff;
}
.bottom-section-head-ctn-left-bottom-white img{
    width: 90%;
    /* height: 50vh; */
    position: absolute;
    top: 3%;
    left: 2%;

}

.bottom-section-head-ctn-right{
    width: 50%;
    text-align: start;
    margin-left: 4%;
}
.bottom-section-head-ctn-right-top{
    position: relative;
    height: 21vw;
    margin-top: 10%;
}
.bottom-section-head-ctn-right-top-pink{
    content: "";
    position: absolute;
    left: 45%;
    z-index: 1;
    width: 25vw;
    height: 22vw;
    background-color: #ed0365;
}
.bottom-section-head-ctn-right-top-white{
    content: "";
    position: absolute;
    left: 40%;
    top: 8%;
    width: 25vw;
    height: 18vw;
    z-index: 2;
    background: #fff;
}
.bottom-section-head-ctn-right-top-white img{
    width: 90%;
    /* height: 50vh; */
    position: absolute;
    top: 3%;
    left: 8%;

}
.bottom-section-head-ctn-right-bottom{
    margin: 2% 6%;
}

.bottom-section-body-ctn{
    width: 88%;
    /* align-self: center; */
    align-self: flex-end;
    justify-content: space-between;
    display: flex;
    align-items: center;
}
.bottom-section-body-ctn-left{
    width: 56%;
    text-align: start;

}
.bottom-section-body-ctn-left h3{
    font-size: 2rem;
    font-weight: 600;
}
.bottom-section-body-ctn-right{
    content: "";
    width: 20%;
    display: flex;
    position: relative;
    align-items: center;
    left: relative;
    height: 30vw;
    background: #c3d1eb;
}
.bottom-section-body-ctn-right-parent{
    display: flex;
    width: 16vw;
    flex-direction: column;
    box-shadow: 0px 3px 15px grey;
    background: #ffffff;
    position: absolute;
    left: -20%;
}
.bottom-section-body-ctn-right-parent p{
    font-size: 0.7rem;
    font-weight: 700;
    line-height: 1.6;
    margin-top: 0;
    margin-bottom: 10%;
}
.bottom-section-body-ctn-right-parent h3{
    margin: 0;
    font-size: 0.8rem;
}
.bottom-section-body-ctn-right-parent img{
    transform: scale(.8);
}
.about-para{
    position: relative;
    height: 45vh;
    overflow-y: auto;
}
.about-para::-webkit-scrollbar,.services-ctn-body-two::-webkit-scrollbar,.services-ctn-body-three::-webkit-scrollbar,.services-ctn-body-four::-webkit-scrollbar,.services-ctn-body-five::-webkit-scrollbar{
    width: 9px;
}
.about-para::-webkit-scrollbar-thumb{
    height: 2px;
    background-color: #ed2e54;
}
.about-para img.read-more-icon{
    position: absolute;
    width: 25px;
    height: 25px;
    cursor: pointer;
}
img.read-less-icon{
    position: absolute;
    width: 20px;
    height: 20px;
    right: 2%;
    opacity: 0.8;
    cursor: pointer;
    bottom: 0;
}
@media(max-width:1300px){
    .about-container p{
        font-size: 1rem;
    }
    .about-top-section>:nth-child(3){
        align-items: center;
    }
    .about-container h2{
        font-size: 2rem;
    }
}
/* @media(max-width:700px){
    .about-top-section-body-ctn {
        margin-bottom: 15%;
    }
} */


@media(max-width:770px){
    
    .blink-circle,.about-container img.about-center-line{
        display: none;
    }
    .about-top-section>:nth-child(1){
        /* height: 100vh; */
        height: auto;
        margin-top: 10%;
        flex-direction: column;
        justify-content: end;
    }
    .about-top-section>:nth-child(2){
        height: auto;
        flex-direction: column;
        justify-content: center;
    }
    .about-top-section>:nth-child(3){
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        height: 100vh;
    }
    .about-top-section-body-ctn-left-one,.about-top-section-body-ctn-right-one,.about-top-section-body-ctn-left-two{
        width: 90%;
        margin: 2%;
    }
    .about-top-section-body-ctn-right-two{
        width: 98%;
        margin: 2%;
        height:25vh
    }
    .about-top-section-body-ctn-left-one-sec{
        width: 98%;
    }
    .bottom-section-head-ctn{
        flex-direction: column;
        width: 98%;
    }
    .bottom-section-head-ctn-left,.bottom-section-head-ctn-right,.bottom-section-head-ctn-right-bottom,.bottom-section-body-ctn-left{
        width: 90%;
        margin: 2%;
        align-self: center;
    }
    .bottom-section-head-ctn-left-top{
        width: 98%;
    }
    .bottom-section-head-ctn-left-bottom,.bottom-section-head-ctn-right-top{
        height: 40vw;
    }
    .bottom-section-head-ctn-left-bottom-pink{
        width: 45vw;
        height: 34vw;
    }
    .bottom-section-head-ctn-left-bottom-white{
        top: 3%;
        width: 38vw;
        height: 32vw;
    }
    .bottom-section-head-ctn-left-bottom-white img{
        top: 10%;
        left: 2%;
        width: 100%;
    }
    .bottom-section-head-ctn-right{
        display: flex;
        flex-direction: column-reverse;
    }
    .bottom-section-head-ctn-right-top-pink{
        width: 45vw;
        height: 34vw;
        right: -12%;
        left: auto;
    }
    .bottom-section-head-ctn-right-top-white{
        width: 38vw;
        height: 32vw;
        right: -6%;
        top: 3%;
        left: auto;
    }
    .bottom-section-head-ctn-right-top-white img{
        width: 100%;
    }
    .bottom-section-body-ctn{
        width: 98%;
    }
    .bottom-section-body-ctn-right{
        position: unset;
        width: 40%;
        justify-content: end;
        align-items: center;
        height: 35vh;
    }
    
    .bottom-section-body-ctn-right-parent{
        position: unset;
        width: 22vw;
        height: 31vh;
        align-items: center;
        justify-content: center;
    }
    .bottom-section-body-ctn-right-parent h3,.bottom-section-body-ctn-right-parent p{
        font-size: 0.6rem;
    }
    .bottom-section-body-ctn-right-parent img{
        width: 90%;
        position: unset;
    }
    .about-top-section-body-ctn img.about-background-img{
        bottom:0%
    }
}
@media(max-width:540px){
    
    .blink-circle,.about-container img.about-center-line{
        display: none;
    }
    .about-container p{
        font-size: 1rem;
    }
    .about-top-section>:nth-child(1){
        height: auto;
        margin-top: 10%;
        flex-direction: column;
        justify-content: center;
    }
    .about-top-section>:nth-child(2){
        height: auto;
        flex-direction: column;
        justify-content: center;
    }
    .about-top-section>:nth-child(3){
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        height: 100vh;
    }
    .about-top-section-body-ctn-left-one,.about-top-section-body-ctn-right-one,.about-top-section-body-ctn-left-two{
        width: 90%;
        margin: 2%;
    }
    .about-top-section-body-ctn-right-two{
        width: 98%;
        margin: 2%;
        height:34vh
    }
    .about-top-section-body-ctn-left-one-sec{
        width: 98%;
    }
    .bottom-section-head-ctn{
        flex-direction: column;
        width: 98%;
    }
    .bottom-section-head-ctn-left,.bottom-section-head-ctn-right,.bottom-section-head-ctn-right-bottom,.bottom-section-body-ctn-left{
        width: 90%;
        margin: 2%;
        align-self: center;
    }
    .bottom-section-head-ctn-left-top{
        width: 98%;
        margin-top: 20%;
    }
    .bottom-section-head-ctn-left-bottom,.bottom-section-head-ctn-right-top{
        height: 40vw;
    }
    .bottom-section-head-ctn-left-bottom-pink{
        width: 45vw;
        height: 34vw;
    }
    .bottom-section-head-ctn-left-bottom-white{
        top: 3%;
        width: 38vw;
        height: 32vw;
    }
    .bottom-section-head-ctn-left-bottom-white img{
        top: 10%;
        left: 2%;
        width: 100%;
    }
    .bottom-section-head-ctn-right{
        display: flex;
        flex-direction: column-reverse;
    }
    .bottom-section-head-ctn-right-top-pink{
        width: 45vw;
        height: 34vw;
        right: -12%;
        left: auto;
    }
    .bottom-section-head-ctn-right-top-white{
        width: 38vw;
        height: 32vw;
        right: -6%;
        top: 3%;
        left: auto;
    }
    .bottom-section-head-ctn-right-top-white img{
        width: 100%;
    }
    .bottom-section-body-ctn{
        width: 98%;
        flex-direction: column;
    }
    .bottom-section-body-ctn-right{
        position: unset;
        width: 98%;
        justify-content: center;
        align-items: center;
        height: 20vh;
    }
    
    .bottom-section-body-ctn-right-parent{
        position: unset;
        width: 60vw;
        height: 15vh;
        align-items: center;
        justify-content: space-between;
    }
    .bottom-section-body-ctn-right-parent h3,.bottom-section-body-ctn-right-parent p{
        font-size: 0.6rem;
    }
    .bottom-section-body-ctn-right-parent img{
        width: 28%;
        position: unset;
    }
    .about-top-section-body-ctn img.about-background-img{
        bottom:0%
    }
}