.careers-card-ctn{
    display: flex;
    flex-direction: column;
    width: 96%;
    background: #ecf2f8;
    border-radius: 6px;
    margin-bottom: 5%;
}
.careers-card-ctn-head{
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: start;
    padding: 3%;
}
.careers-card-ctn-head p{
    margin: 0%;
    width: 30%;
    font-size: 0.8rem;
    color: #000;
}
.careers-card-ctn-head h3{
    font-size: 1.3rem;
    font-weight: 500;
}
.careers-card-ctn-head p.right-edge{
    margin: 0%;
    position: absolute;
    top: 35%;
    left: 75%;
    font-size: 0.8rem;
    color: #000;
    font-weight: 600;
}
.careers-card-ctn-body{
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: start;
    padding: 0 3%;
}
.careers-card-ctn-body ul{
    padding-inline-start: 10px;
    margin-top: 0%;
    height: 35vh;
}
.careers-card-ctn-body ul li{
    font-size: 0.9rem;
    margin: 2%;
    width: 80%;
}
/* .careers-card-ctn-body ul li::before {
    content: "\2022";
    color: red;
    font-weight: bold;
    display: inline-block; 
    width: 1em;
    margin-left: -1em;
  } */

.careers-card-ctn-footer{
    width: 85%;
    display: flex;
    padding: 3%;
    padding-top: 0%;
    justify-content: flex-end;
}
.careers-card-ctn-footer button{
    width: 35%;
    height: 30px;
    border: none;
    border-radius: 3px;
    color: #fff;
    background: linear-gradient(to right, #2d8a9e 0%, #8689ed 100%);
    cursor: pointer;
}

@media (max-width:1260px){
    .careers-card-ctn-body ul{
        height: 30vh;
    }
    .careers-card-ctn-body ul li{
        font-size: 0.7rem;
    }
}