.gitex-container{
    position: fixed;
    bottom: 10%;
    right: 1%;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: #e6fde2;
    box-shadow: 0 0 0.25em 0.25em #eee;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5%;
    /* border: 1px solid red; */
   
    animation: pulse 2s ease-out infinite;


}
.gitex-container::after {
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    border-radius: 50%;
    position: absolute;
  }
  
  @keyframes pulse {
    0% { box-shadow: 0 0 0 0px #96f985,0 0 0 0px #cbf9c3;  }
    /* 50% { transform: scale(0.95); } */
    100% { box-shadow: 0 0 0 15px rgba(5, 188, 230, 0),0 0 0 30px rgba(0,210,255,0); }
  }

.gitex-container-first-p{
    font-size: 0.9rem;
    font-weight: 500;
    margin: 0;
}
.gitex-container-second-p{
    font-size: 0.6rem;
    font-weight: 500;
    margin: 3% 0;
}
.location-icon{
    color: #dd0404;
    font-size: 0.8rem;
    margin-right: 2px;
}
.gitex-container img{
  width: 170px;
}
.gitex-container-third-p{
    font-size: 0.8rem;
    /* position: absolute; */
    /* bottom: 36%;
    left: 59%; */
    /* font-family: fantasy; */
    font-weight: 700;
    color: #E4007D;
    margin: 0;

}
.gitex-container-four-p{
    font-size: 0.85rem;
    font-weight: 500;
    margin: 0;
}

@media(max-width:770px){
    .gitex-container-first-p{
        font-size: 0.7rem;
    }
    .gitex-container-second-p{
        font-size:7px;
        margin: 1% 0 2% 0;
    }
    .gitex-container img{
        width: 150px;
    }
    .gitex-container-third-p{
        font-size: 0.7rem;
    }
    .gitex-container-four-p{
        font-size: 0.75rem;
    }
    .gitex-container{
        right: 5%;
    }
}


