.blink-circle{
    position: absolute;
    top: 10%;
    left: 97.8%;
}
.blink-circle-parent{
    content: "";
    width: 2vw;
    height: 2vw;
    border-radius: 50%;
    
    position: relative;
}
.blink-circle-middle{
    content: "";
    width: 3vw;
    height: 3vw;
    border-radius: 50%;
   
    opacity: 0.5;
    position: absolute;
    top: -23%;
    left: -25%;
}
.blink-circle-outer{
    content: "";
    width: 4vw;
    height: 4vw;
    border-radius: 50%;
    
    opacity: 0.2;
    position: absolute;
    top: -46%;
    left: -50%;
}