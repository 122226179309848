.top-bar-parent{
  width: 96%;
  max-height: 15px;
  background-color: #fff;
  height: 8vh;
  padding: 2% 2% 1%;
  display: flex;
  align-items: center;
  position: sticky;
  z-index: 10;
  justify-content: space-between;
  top: 0;  
}

.top-bar-parent-left{
    display: flex;
    width: 50%;
    justify-content: flex-start;
}
.top-bar-parent-left a{
    width: 20%;
}
.top-bar-parent-left img{

    width: 75%;
}
.top-bar-parent-right{
    width: 45%;
}
.top-bar-parent-right ul{
  list-style: none;
  display: flex;
  justify-content: space-around;
  padding: 0;
}
.top-bar-parent-right ul li {
    display: flex;
    justify-content:space-around;
    font-size: 0.8em;
    font-weight: 500;
}

.top-bar-parent-right ul li a{
  
    text-decoration: none;
    color: #000;
}
/* .top-bar-parent-right ul li a.active{
    
    font-weight: 800;
} */

.top-bar-parent-right ul li a:hover{
    border-bottom: 2px solid #46b07c;
    animation: border 0.2s ease-in;
}

/* .top-bar-parent-right ul li a.active{
  
    border-bottom: 2px solid #46b07c;
} */

.services-active, .products-active, .home-active, .about-active,.careers-active,.contact-active{
    font-weight: 800;
}
.active{
    font-weight: 800;
    color: red;
}
.top-bar-parent-responsive-switch{
    display: none;
}


.top-bar-parent-responsive-switch-menu{
    width: 98%;
    height: 27vh;
    max-height: 250px;
    background: #fff;
}
.top-bar-parent-responsive-switch-menu ul{
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0;
  }
.top-bar-parent-responsive-switch-menu ul li {
    display: flex;        
    justify-content:space-around;
    font-size: 1rem;
    margin: 1%;
    font-weight: 500;
}
.top-bar-parent-responsive-switch-menu ul li a{
  
    text-decoration: none;
    color: #000;
}
/* media query */
/* media query */
/* media query */


@media (max-width:770px){
    .top-bar-parent-left img{
        width: 120px;
    }
    .top-bar-parent{
        justify-content: center;
        max-height: 60px;
        padding: 0% 2% 1%;
    }
    .top-bar-parent-left{
        width: 90%;
    }
    .top-bar-parent-responsive-switch{
        display: flex;
    }
    .top-bar-parent-right{
        display: none;
    }
    .top-bar-parent-responsive-switch-menu{
        position: absolute;
        top: 100%;
    }

}
@media (max-height:700px){
    .top-bar-parent-responsive-switch-menu{
        height: 35vh;
    }
}

@media (min-width:1851px) {
    .top-bar-parent{
      margin: 0 auto;
      width: 1850px;
    }
  }